import React, { useContext, useEffect, useState } from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { Cog, FileText } from "lucide-react"
import { Button } from "components/ui/button"
import { SubOrgContext } from 'context/SubOrganizationContext'
import { useNavigate, useParams } from 'react-router-dom'
import UpdateCollectionDialog from './UpdateCollection'
import { Context } from 'context/GlobalState'
import { projectService } from 'api/services/BEX/project.service'
import { Label } from 'components/ui/label'
import { Input } from 'components/ui/input'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { fileManagerService } from 'api/services/BEX/fileManager.service'

export default function Collection() {

    const [openModal, setOpenModal] = useState(false)

    const { project } = useContext(SubOrgContext)
    

    return (
        <Card>
            <CardHeader>
                <div className='flex justify-between'>
                    <div>
                        <CardTitle>Document Collections</CardTitle>
                        <CardDescription>Manage your document collections and permissions</CardDescription>
                    </div>
                    <dir>
                        <Button onClick={() => setOpenModal(true)}> Create New Collection </Button>
                    </dir>
                </div>

            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    <div className="grid grid-cols-3 gap-4">
                        {project && project.collections.map((item, index) => (
                            // <CollectionItem project={project} {...item} index={index} key={index} />
                            
                            <CollectionCard project={project} {...item} index={index} key={index} />
                        ))}
                    </div>
                    <Button variant="outline">
                        <FileText className="mr-2 h-4 w-4" />
                        Manage Collections
                    </Button>
                </div>
            </CardContent>

            {/* create collection modal */}
            <CreateNewCollectionDialog openModal={openModal} setOpenModal={setOpenModal} />
        </Card>
    )
}

function CollectionCard({ name, _id }) {

    const [show, setShow] = useState(false)
    const [docs, setDocs] = useState([])
    const [docsLoading, setDocsLoading] = useState(true)

    const navigate = useNavigate()
    const {oragID, subOragID} = useParams()

    const getDocs = async () => {
        try {
            const res = await fileManagerService.listFiles(oragID, `coll-${subOragID}-${_id}`)
            console.log('res: ', res);
            setDocs(res.data)
        } catch(err) {
            console.log('err: ',err);
        } finally {
            setDocsLoading(false)
        }
    }

    useEffect(() => {getDocs()}, [])

    return (
        <Card>
            <CardHeader>
                <CardTitle onClick={() => navigate(`${_id}/documents`)} className='cursor-pointer'>{name}</CardTitle>
                <CardDescription>{!docsLoading ? `${docs.length} documents` : '...'}</CardDescription>
            </CardHeader>
            <CardContent>
                <Button onClick={() => setShow(true)} variant="outline" size="sm">
                    <Cog className="mr-2 h-4 w-4" />
                    Manage Collection
                </Button>
            </CardContent>

            {/* update collection dialog */}
            <UpdateCollectionDialog collectionId={_id} show={show} setShow={setShow} />
        </Card>
    )
}

const CreateNewCollectionDialog = ({ openModal, setOpenModal }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [collectionName, setCollectionName] = useState('')


    const { oragID, subOragID  } = useParams()
    const { addNewNotifcation } = useContext(Context)
    const { project, actions } = useContext(SubOrgContext)

    const handleCreateCollection = () => {
        if (!project) {
            return addNewNotifcation('To create collections, please subscribe to a plan.')
        }

        setBtnLoading(true)
        const collectionObj = {
            name: collectionName,
            organizationId: oragID,
            subOrganizationId: subOragID,
            projectId: project._id
        }

        projectService.addCollection(collectionObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Collection created successfully', 'success')
            actions({type: 'SET_PROJECT', payload: (project => ({...project, collections: res.data.collections}))})
            setOpenModal(false)
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 403) {
                addNewNotifcation('Resource limit reached. You cannot create more collections under your current plan', 'danger')
            } else if (error.response?.status === 409) { 
                addNewNotifcation(`Collection with ${collectionName} already created`, 'danger')
            } else {
                addNewNotifcation(`Something went wrong. Please try again`, 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }
    

    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Create New Collection</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Set up a new collection.</DialogDescription>
                </DialogHeader>
                <div className='flex w-full my-4'>
                    <div className='flex flex-col w-full gap-2'>
                        <Label htmlFor='collection-name'> Collection Name </Label>
                        <Input id='collection-name' value={collectionName} onChange={(e) => setCollectionName(e.target.value)} />
                    </div>
                </div>

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                    <Button disabled={btnLoading} onClick={handleCreateCollection} className="px-5 py-3 text-md">
                        Create Collection
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}