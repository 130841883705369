import React, { createContext, useState, useEffect } from 'react';
import { watsonxService } from 'api/services/PROJECT-O/WATSONX.service';
import { groqService } from 'api/services/PROJECT-O/GROQ.service';
import labHelper from '../classes/AILabHelper';
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service';

export const GlobalContext = createContext();

export const DemoProvider = ({ children }) => {
    const [groqModels, setGroqModels] = useState("");
    const [query, setQuery] = useState("");
    const [selectedTask, setSelectedTask] = useState("");
    const [instruction, setInstruction] = useState("");
    const [context, setContext] = useState("");
    const [targetFormat, setTargetFormat] = useState("");
    const [sampleInput, setSampleInput] = useState("");
    const [sampleOutput, setSampleOutput] = useState("");
    const [inputData, setInputData] = useState("");
    const [output, setOutput] = useState("");
    const [sampleFiles, setSampleFiles] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [messages, setMessages] = useState([{role: "system", msg: "Hi. Please select a document to start querying.", _id: Date.now()}]);
    const [messageLoading, setMessageLoading] = useState(false);
    const [selectedModelType, setSelectedModelType] = useState("watsonx");
    const [selectedModel, setSelectedModel] = useState("");
    const [isGenerating, setIsGenerating] = useState(false);
    const [parametersWatsonX, setParametersWatsonX] = useState({
        mode: "greedy",
        temperature: 0.7,
        max_tokens: 0, max_new_tokens: 200,
        top_p: 1, top_k: 50,
        stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
        repetition_penalty: 1.2});
    const [parametersGroq, setParametersGroq] = useState({
        max_tokens: 200,
        n: 1,
        top_p: 1,
        stop: ["<|endoftext|>", "<|endofcode|>"],
        temperature: 0,
        frequency_penalty: 1.2,
        presence_penalty: 0,
        response_format: {"type": "text"},
        });

    const SendWatsonXPrompt = async () => {
        try {
            let prompt = labHelper.ConstructPromptUnified({
                "instruction": instruction,
                "context": context,
                "target_format": targetFormat,
                "sample_input": sampleInput,
                "sample_output": sampleOutput,
                // "input_data": inputData,
            });

            let params = parametersWatsonX;
            if (parametersWatsonX.mode === "greedy") {
                params = Object.fromEntries(
                    Object.entries(parametersWatsonX).filter(([key]) => key === "max_new_tokens" || key === "mode")
                );
            }

            const body = {
                "modelId": selectedModel,
                "parameters": params,
                "prompt": prompt,
                "query": inputData,
            }

            console.log("Sent Prompt\n", prompt);
            const res = await unifiedModelService.Experiment(body, "IBM");
            console.log(res);
            setOutput(res.data.result.prediction);
        }catch (error) {
            throw new Error(error);
        }
    }

    const SendGroqPrompt = async () => {
        try {
            let prompt = labHelper.ConstructPromptUnified({
                "instruction": instruction.trim(),
                "context": context.trim(),
                "target_format": targetFormat.trim(),
                "sample_input": sampleInput.trim(),
                "sample_output": sampleOutput.trim(),
                // "input_data": inputData,
            });

            const body = {
                "modelId": selectedModel,
                "parameters": parametersGroq,
                "prompt": prompt,
                "query": inputData.trim()
            }
            
            console.log("Sent Prompt\n", prompt);
            const res = await unifiedModelService.Experiment(body, "Groq");
            console.log(res)
            setOutput(res.data.result.choices[0].message.content);
        }catch (error) {
            throw new Error(error);
        }
    }

    const LoadGroqModels = async () => {
        try {
            const res = await groqService.ListModels();
            setGroqModels(res.data.models.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        LoadGroqModels();
    }, []);

    
    const Clear = () => {
        setInstruction('');
        setContext('');
        setTargetFormat('');
        setSampleInput('');
        setSampleOutput('');
        setInputData('');
        setOutput('');
        setSelectedModel('');
        setSelectedTask('');
    }

    const values = {
        groqModels,
        query, setQuery,
        selectedTask, setSelectedTask,
        instruction, setInstruction,
        context, setContext,
        targetFormat, setTargetFormat,
        sampleInput, setSampleInput,
        sampleOutput, setSampleOutput,
        inputData, setInputData,
        output, setOutput,
        sampleFiles, setSampleFiles,
        selectedDocuments, setSelectedDocuments,
        messages, setMessages,
        messageLoading, setMessageLoading,
        selectedModelType, setSelectedModelType,
        selectedModel, setSelectedModel,
        parametersWatsonX, setParametersWatsonX,
        parametersGroq, setParametersGroq,
        isGenerating, setIsGenerating,
        SendWatsonXPrompt,
        SendGroqPrompt,
        Clear
    }

    return (
        <GlobalContext.Provider value={values}>
            {children}
        </GlobalContext.Provider>
    );
};
