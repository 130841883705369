import { Folder, ChevronDown, ChevronRight } from 'lucide-react'

const FileTreeItem = ({ item, onSelect, selectedFolder, expandedFolders, onToggleExpand }) => {
    if (item.type === "file") return null;
    const isExpanded = expandedFolders.includes(item.id);

    
    return (
        <div>
            <div
                className={`select-none text-sm flex items-center w-fit min-w-[200px] cursor-pointer p-1 rounded-sm my-1 hover:bg-gray-100 ${selectedFolder === item.id ? "bg-gray-100" : ""}`}
                onClick={() => onSelect(item)}
            >
                <span
                    className="mr-1 w-4 h-4 flex items-center justify-center"
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggleExpand(item.id);
                    }}
                >
                    {
                    // item.children &&
                    item.children.some(child => child.type === "folder") ? 
                    (isExpanded ? (
                        <ChevronDown className="w-4 h-4" />
                    ) : (
                        <ChevronRight className="w-4 h-4" />
                    )) : (
                        <ChevronRight className="w-4 h-4 text-transparent" />
                    )}
                </span>
                <Folder className="text-yellow-600 w-4 h-4 mr-2 flex-none" />
                <span className='w-full'>{item.name}</span>
            </div>
            {isExpanded && item.children && (
                <div className="ml-3">
                    {item.children.map((child) => (
                        <FileTreeItem
                            key={child.id}
                            item={child}
                            onSelect={onSelect}
                            selectedFolder={selectedFolder}
                            expandedFolders={expandedFolders}
                            onToggleExpand={onToggleExpand}
                        />
                    ))}
                </div>
            )}
        </div>
    );    
};

export default FileTreeItem;