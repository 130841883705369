import React, { createContext, useState, useEffect } from 'react';
import { unifiedModelService } from "api/services/PROJECT-O/UnifiedModel.service";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";


export const OpCodeBuilderContext = createContext({});

export const OpCodeBuilderProvider = ({ children }) => {

    const [currentStep, setCurrentStep] = useState(0);
    const [savedModels, setSavedModels] = useState([]);
    const [opCodes, setOpCodes] = useState([]);
    const [opCodesDetails, setOpCodesDetails] = useState([]);
    const [selectedOpCodesDetails, setSelectedOpCodesDetails] = useState({});
    const [isModelLoading, setIsModelLoading] = useState([]);
    const [isOpcodeLoading, setIsOpcodeLoading] = useState([]);

    // Create OpCode
    const [opCodeName, setOpCodeName] = useState("");
    const [opCodeDescription, setOpCodeDescription] = useState("");
    const [inputGroups, setInputGroups] = useState([{ modelName: "", input: ['email_content'], output: "" }]);
    const [isCreating, setIsCreating] = useState(false);


    // Execute OpCode
    const [selectedOpCode, setSelectedOpCode] = useState("");
    const [executeInput, setExecuteInput] = useState("");
    const [executionResults, setExecutionResults] = useState([]);
    const [isExecuting, setIsExecuting] = useState(false);
    

    const handleGetSavedModels = async () => {
        try {
            setIsModelLoading(true)
            console.log("getting models")
            const res = await unifiedModelService.GetModelsByOrgSubOrg("demo", "demo", "IBM");
            console.log(res)
            const foundationModels = res.data.FoundationModels
            console.log("models: ", foundationModels)
            setSavedModels(foundationModels.sort());
        } catch (error) {
            console.error("Failed to get saved models", error);
        } finally {
            setIsModelLoading(false)
        }
    };
    
    const handleGetSavedOpcodes = async () => {
        try {
            setIsOpcodeLoading(true)
            console.log("getting opcodes")
            const res = await opCodeService.GetOpCodesByOrgSubOrg("demo", "demo");
            setOpCodesDetails(res.data.opcodes)
            const opCodes = res.data.opcodes.map(opcode => opcode.opcode_id);
            console.log("opcodes: ", opCodes)
            setOpCodes(opCodes.sort());
        } catch (error) {
            console.error("Failed to get saved models", error);
        } finally {
            setIsOpcodeLoading(false)
        }
    };
 
    useEffect(() => {
        handleGetSavedModels();
        handleGetSavedOpcodes()
    }, []);

    const value={
        currentStep, setCurrentStep,
        savedModels, setSavedModels,
        opCodes, setOpCodes,
        opCodesDetails,
        selectedOpCodesDetails, setSelectedOpCodesDetails,
        isModelLoading, setIsModelLoading,
        isOpcodeLoading, setIsOpcodeLoading,
        handleGetSavedModels,
        handleGetSavedOpcodes,


        opCodeName, setOpCodeName,
        opCodeDescription, setOpCodeDescription,
        inputGroups, setInputGroups,
        isCreating, setIsCreating,
        selectedOpCode, setSelectedOpCode,
        executeInput, setExecuteInput,
        executionResults, setExecutionResults,
        isExecuting, setIsExecuting,
    }

    return (
        <OpCodeBuilderContext.Provider value={value}>
            {children}
        </OpCodeBuilderContext.Provider>
    );
};