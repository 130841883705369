import { useContext, useEffect } from 'react'
import { Combobox } from 'components/ui/combobox';
import { Button } from "components/ui/button";
import { Settings2, RotateCcw } from 'lucide-react';
import { Anatomy } from './Anatomy';
import { RAG } from './RAG';
import { Output } from './Output';
import { GlobalContext } from 'pages/PromptingAndRagTraining/context/LivePromptContext';
import watsonxModels from "../../json/watsonxModels";
import { WatsonXConfiguration } from './WatsonXConfiguration';
import { GroqConfiguration } from './GroqConfiguration';
import { LoaderSpinner } from 'components/LoaderSpinner';
import { Context } from 'context/GlobalState';
import SaveModelDialogIBM from './SaveModelIBM';
import SaveModelDialogGroq from './SaveModelGroq';
import ConsolidatedDialogLive from './ConsolidatedPromptLive';

const LivePrompt = () => {

    return (
        <div className="w-full grid md:grid-cols-[3fr_2fr] gap-4 h-fit pb-8">

            <Anatomy/>

            <div className="grid gap-4 h-fit">
                <RAG/>
            </div>

            <div className="col-span-2">
                <Output/>
            </div>
            <Footer/>
        </div>
    );
};

const Footer = () => {

    const MODEL_TYPES = [
        { _id: 1, name:'WatsonX', value:"watsonx" },
        { _id: 2, name:'Groq', value:"groq" }
    ]

    const { addNewNotifcation } = useContext(Context)

    const {
        selectedModelType, setSelectedModelType,
        selectedModel, setSelectedModel,
        isGenerating, setIsGenerating,
        instruction,
        SendWatsonXPrompt,
        SendGroqPrompt,
        groqModels,
        Clear
    } = useContext(GlobalContext);

    const handleGenerate = async () => {
        if (!selectedModel){
            addNewNotifcation("Please select a foundation model.", "warning");
            return;
        }
        if (!instruction.trim()){
            addNewNotifcation("Please enter an instruction.", "warning");
            return;
        }
        try{
            setIsGenerating(true);
            if (selectedModelType === "watsonx"){
                await SendWatsonXPrompt();
            }
            else if (selectedModelType === "groq"){
                await SendGroqPrompt();
            }
        } catch(err) {
            console.log(err);
            addNewNotifcation("Error generating response.", "error");
        } finally {
            setIsGenerating(false);
        }
    };

    const clearPrompt = () => {
        Clear();
    };

    useEffect(() => {
        if (selectedModelType === "watsonx") {
            console.log("watson")
            if (!watsonxModels.find(model => model.value === selectedModel)){
                setSelectedModel('');
                console.log("watson reset")
            }
        }
        else if (selectedModelType === "groq"){
            console.log("groq")
            if (!groqModels.find(model => model.id === selectedModel)){
                setSelectedModel('');
                console.log("groq reset")
            }
        }
        
        
    }, [selectedModelType]);


    return (
        <footer className="flex col-span-2 items-center justify-end h-[60px] w-[calc(100%)]
        bg-white border-gray-300 py-4 z-50 fixed bottom-0 left-0 right-0 bg-white transform
        -translate-y-0 -translate-x-0
        select-none">
            <div className="flex items-center justify-between gap-2 px-4 w-full">
                <div className="flex gap-2">
                    {/* <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button size="icon" variant="ghost">
                                    <Info className="h-5 w-5" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent className="w-[300px]" align="start">
                                <p>{modelUseCase ? modelUseCase : 'Select a foundation model to see use cases.'}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider> */}

                    <Combobox
                        defaultDisplayed='Select Model Type'
                            items={MODEL_TYPES}
                            selectedValue={MODEL_TYPES.find((item) => item.value === selectedModelType)}
                            setter={(value)=>setSelectedModelType(MODEL_TYPES.find(item => value._id === item._id).value)}
                    />
                    
                    {/* MODEL TYPE */}
                    { selectedModelType === "watsonx" && <WatsonXConfigs /> }
                    { selectedModelType === "groq" && <GroqConfigs /> }
                    <ConsolidatedDialogLive />
                    { selectedModelType === "watsonx" && <SaveModelDialogIBM /> }
                    { selectedModelType === "groq" && <SaveModelDialogGroq /> }

                    
                </div>
                <div className="flex items-center gap-2">
                    <Button size="icon" variant="ghost" onClick={clearPrompt}>
                        <RotateCcw className="h-5 w-5" />
                    </Button>
                    <Button variant="default" onClick={handleGenerate} disabled={isGenerating}>{isGenerating ? <LoaderSpinner/> : "Generate"}</Button>
                </div>
            </div>
        </footer>
    );
};

export default LivePrompt;


const WatsonXConfigs = () => {
    const {
        selectedModel, setSelectedModel
    } = useContext(GlobalContext);

    return(
        <div className="flex items-center gap-2">
            <Combobox
                defaultDisplayed='Select Watsonx Model'
                className=""
                    items={watsonxModels}
                    selectedValue={watsonxModels.find((item) => item.value === selectedModel)}
                    setter={(value)=>setSelectedModel(watsonxModels.find(item => value._id === item._id).value)}
            />
            <WatsonXConfiguration
                trigger={
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                        <Settings2 className="h-5 w-5" />
                    </Button>
                }
            />
        </div>
    );
}

const GroqConfigs = () => {
    const {
        groqModels,
        selectedModel, setSelectedModel
    } = useContext(GlobalContext);

    const GROQ_MODELS = Array.isArray(groqModels) && groqModels.length > 0
    ? groqModels
        .filter(model => !model.id.includes("whisper")) // Filter out models that include "whisper"
        .sort((a, b) => a.id.localeCompare(b.id)) // Sort the models by their id property
        .map((model, index) => ({
            _id: index,
            name: model.id,
            value: model.id
        }))
    : [];

    return(
        <div className="flex items-center gap-2">
            <Combobox
                defaultDisplayed='Select Groq Model'
                className=""
                    items={GROQ_MODELS}
                    selectedValue={GROQ_MODELS.find((item) => item.value === selectedModel)}
                    setter={(value)=>setSelectedModel(GROQ_MODELS.find(item => value._id === item._id).value)}
            />

            <GroqConfiguration
                trigger={
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                        <Settings2 className="h-5 w-5" />
                    </Button>
                }
            />
        </div>
    );
}