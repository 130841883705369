class OpCodeBuilderHandler {
    // Validate values (should not be empty and follow variable naming rules)
    validateOpCodeName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `OpCode ID field cannot be empty.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `OpCode ID cannot contain spaces.` };
        }
        if (value.length < 4){
            return { isValid: false, message: `OpCode ID should be at least 4 characters long.` };
        }
        return { isValid: true, message: `Valid OpCode ID.` };
    }

    validateOutputName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Output name field cannot be empty.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Output name cannot contain spaces.` };
        }
        if (value.length < 4){
            return { isValid: false, message: `Output name should be at least 4 characters long.` };
        }
        
        return { isValid: true, message: `Valid Output name.` };
    }

    validateGlobalVariableName(value) {
        // value = value.trim()
        // if (!value) {
        //     return { isValid: false, message: `Field cannot be empty.` };
        // }
        if (value.length < 4 && value.length > 0){
            return { isValid: false, message: `Global Variable Name should be at least 4 characters long.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Global Variable Name cannot contain spaces.` };
        }
        return { isValid: true, message: `Valid value.` };
    }
    
    validateVariableName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Variable Name field cannot be empty.` };
        }
        if (value.length < 4 && value.length > 0){
            return { isValid: false, message: `Variable Name should be at least 4 characters long.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Variable Name cannot contain spaces.` };
        }
        return { isValid: true, message: `Valid value.` };
    }

}

// Example usage
const handler = new OpCodeBuilderHandler();
export default handler;
