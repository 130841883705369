import React, { useContext, useState, useEffect } from 'react';
import { Button } from "components/ui/button";
import { Save } from 'lucide-react';
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "components/ui/tooltip";
import {LoaderSpinner} from "components/LoaderSpinner";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog";
import { Textarea } from "components/ui/textarea";
import { useParams } from 'react-router-dom';
import { Context } from 'context/GlobalState';
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service';
import { GlobalContext } from "pages/PromptingAndRagTraining/context/LivePromptContext";
import labHelper from '../../classes/AILabHelper';
import InputValidation from 'components/InputValidation';


const SaveModelDialog = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const { addNewNotifcation } = useContext(Context);
    const param = useParams()
    const {
        parametersWatsonX, setParametersWatsonX,
        selectedModel, instruction, context,
        targetFormat, sampleInput, sampleOutput
    } = useContext(GlobalContext);


    const handleOnOpen = (e) => {
        if (!instruction || !selectedModel) {
            addNewNotifcation("Please select a model and an instruction to save.");
            e.preventDefault()
            setOpen(false);
            return;
        }
        setOpen(true);
    }


    const OnSaveModelIBM = async () => {
        let { isValid: isUniqueNameValid, message: uniqueNameMessage } = validateUniqueName(name.trim());
        if (!isUniqueNameValid) {
            addNewNotifcation(uniqueNameMessage, "warning");
            return;
        }


        try{
            setLoading(true);
            // Save function here
            const body = {
                "model_type": "IBM",
                "unique_name": name,
                "modelId": selectedModel,
                "prompt": labHelper.ConstructPromptUnified({
                    "instruction": instruction.trim(),
                    "context": context.trim(),
                    "target_format": targetFormat.trim(),
                    "sample_input": sampleInput.trim(),
                    "sample_output": sampleOutput.trim(),
                    // "input_data": inputData,
                }),
                "parameters": {
                    "min_new_tokens": 10,
                    "max_new_tokens": parametersWatsonX.max_new_tokens,
                    "temperature": parametersWatsonX.mode === "sampling" ? parametersWatsonX.temperature : 0,
                    "repetition_penalty": parametersWatsonX.repetition_penalty,
                    "stop_sequence": parametersWatsonX.stop_sequences,
                    "top_k": parametersWatsonX.mode === "sampling" ? parametersWatsonX.top_k : 50,
                    "top_p": parametersWatsonX.mode === "sampling" ? parametersWatsonX.top_p : 1,
                    "decoding_method": parametersWatsonX.mode
                },
                "org_id": "demo",
                "sub_org_id": "demo",
                "modelDescription": description,
                "placeholders": {},
                "metadata": {}
              }
            console.log(body);
            const res = await unifiedModelService.CreateModel(body);
            console.log("result", res);
            addNewNotifcation("Model saved successfully.", "success");
            setOpen(false);
        }
        catch(error){
            console.log("Model not saved.", error);
            if (error.response.data.detail === "Failed to create IBM Foundation Model. Model with the same unique name already exists."){
                addNewNotifcation("Model name exists. Please choose another name.", "warning");
            }
            else {
                addNewNotifcation("Model not saved. Please try again.", "danger");
            }
        } finally {
            setLoading(false)
            setName('')
            setDescription('')
        }
    }


    const validateUniqueName = (uniqueName) => {
        if (!uniqueName) return { isValid: false, message: `Unique Name field cannot be empty.` };
        if (/\s/.test(uniqueName)) return { isValid: false, message: `Unique Name cannot contain spaces.` };
        if (uniqueName.length < 4) return { isValid: false, message: `Unique Name should be at least 4 characters long.` };
        return { isValid: true, message: `Valid Unique Name.` };
    };  


    return (
        <Dialog open={open} onOpenChange={setOpen} >
            {/* <TooltipProvider> */}
                {/* <Tooltip> */}
                    {/* <TooltipTrigger asChild> */}
            <DialogTrigger asChild>
                <Button variant="outline" onClick={handleOnOpen}>
                    {/* <Save className="h-5 w-5" /> */}
                    Save Instruction
                </Button>
            </DialogTrigger>
                    {/* </TooltipTrigger> */}
                    {/* <TooltipContent>
                        <p>Save Your Model's prompt and configurations.</p>
                    </TooltipContent> */}
                {/* </Tooltip> */}
            {/* </TooltipProvider> */}
            <DialogContent className="sm:max-w-[600px] select-none">
                <DialogHeader>
                    <DialogTitle>Save Instruction</DialogTitle>
                    <DialogDescription>
                        Please make sure that you have experimented with your instruction and are satisfied with the output it is giving.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Unique Name
                        </Label>
                        <InputValidation
                            id="name"
                            value={name}
                            onChange={(e)=>{setName(e.target.value)}}
                            className="col-span-3"
                            placeholder="Enter a unique name for your instruction"
                            validation={validateUniqueName}
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="description" className="text-right">
                            Description
                        </Label>
                        <Textarea
                            id="description"
                            value={description}
                            onChange={(e)=>{setDescription(e.target.value)}}
                            className="col-span-3"
                            placeholder="Enter description for your instruction"
                        />
                    </div>
                </div>
                <DialogFooter>
                    <Button type="submit" onClick={OnSaveModelIBM} disabled={loading}>{loading ? <LoaderSpinner/> : "Save"}</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
  }


  export default SaveModelDialog;