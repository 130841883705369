import FileManager from 'components/FileManager/FileManager'
import { SubOrgContext } from 'context/SubOrganizationContext'
import React, { useContext } from 'react'
import Breadcrumb from 'components/Breadcrumb';

export default function FileManagerDocument() {

    const { project } = useContext(SubOrgContext)

    return (
        <div className='flex flex-col flex-grow'>
            <div className='my-3 px-2'>
                <Breadcrumb maxPaths={3} />
            </div>

            <FileManager project={project}/>
        </div>
    )
}
