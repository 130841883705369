import { useState, useRef, useContext, useEffect } from 'react';
import { Send, Settings, Upload, RotateCcw } from 'lucide-react';
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Slider } from "components/ui/slider";
import { Switch } from "components/ui/switch";
import { Label } from "components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { ScrollArea } from "components/ui/scroll-area";
import { DocumentList } from './DocumentList';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../HotelOpsStyles.css";
import { v4 as uuidv4 } from 'uuid';
import { SubOrgContext } from "context/SubOrganizationContext";
import { OrganizationContext } from "context/OrganizationContext";
import { HotelOpsContext } from '../HotelOpsContext';
import hoHelper from '../HotelOpsHelper';
import { Context } from 'context/GlobalState';
import { useParams, Outlet } from 'react-router-dom';

export default function HotelOpDemo() {
    const {
        messages, setMessages,
        uploadedDocs,
    } = useContext(HotelOpsContext);
    const { user, addNewNotifcation } = useContext(Context);
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);
    const [input, setInput] = useState('');
    const [showConfig, setShowConfig] = useState(false);
    const [aiConfig, setAIConfig] = useState({
        wordLimit: 100,
        outputFormat: 'paragraph',
        includeReferences: false,
        includeImages: false
    });
    const [iframeSrc, setIframeSrc] = useState("");
    const [keyUpdate, setKeyUpdate] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const selectDataStore = (selectedOrganization) => {
        if (selectedOrganization._id === "66f52d1c199b72e9c4ad709a") {
            console.log("Using Milvus for organization ID:", selectedOrganization._id);
            return "Milvus";
        } else {
            console.log("Using MongoDB for organization ID:", selectedOrganization._id);
            return "MongoDB";
        }
    };

    const handleSend = async () => {
        if (input.trim()) {
            setMessages(prev => [...prev, { id: prev.length + 1, role: 'user', content: input }]);
            setInput('');
            setIsLoading(true);
            try {
                const filter = {
                    userId: user._id,
                    organizationId: selectedOrganization._id,
                    subOrganizationId: selectedSubOrganization._id,
                    assistant: 'hotel-operations',
                    document_collection: "user"
                }

                // Determine whether to use Milvus or MongoDB based on the organization
                const dataStore = selectDataStore(selectedOrganization);
                let ConstructedRAG;

                if (dataStore === "Milvus") {
                    const MilvusRAGresponse = await hoHelper.QueryEmbeddings(input, selectedOrganization._id, selectedSubOrganization._id);
                    const documents = MilvusRAGresponse.data.results;
                    ConstructedRAG = await hoHelper.constructMilvusRetrievedResults(documents, MilvusRAGresponse);
                } else {
                    // Handle MongoDB interaction here (this is a placeholder, replace with actual MongoDB query logic)
                    const MongoDBResponse = await hoHelper.QueryAllDocs(filter, input, selectedOrganization._id, process.env.REACT_APP_DEMO_MODE);
                    ConstructedRAG = hoHelper.constructMongoDBRetrievedResults(MongoDBResponse.data.results, uploadedDocs);
                }

                const AIResponse = await hoHelper.ConstructPromptGroq(ConstructedRAG, input, aiConfig);
                console.log(AIResponse);
                const result = AIResponse.data.choices[0].message.content;

                setMessages(prev => [...prev, { id: prev.length + 1, role: "assistant", content: result }]);

            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleClear = () => {
        setMessages([{ id: 1, role: "assistant", content: "Hi! Feel free to ask questions and query from your hotel documents." }]);
        setIframeSrc("");
        setKeyUpdate(1);
    }

    useEffect(() => {
        const md = document.querySelectorAll(".custom-markdown");
        const links = [];
        md.forEach(parent => {
            const childLinks = parent.querySelectorAll("a");
            links.push(...childLinks); // Add all links to the links array
        });
        links.forEach(link => {
            link.addEventListener("click", (event) => {
                event.preventDefault();
                setIframeSrc("");
                setKeyUpdate(prev => prev + 1);
                setIframeSrc(link.href);
            });
        });
    }, [messages]);

    return (
        <div className="flex h-full w-full bg-gray-100">
            {/* Document Display */}
            <div className="w-1/2 p-4 bg-white flex flex-col rounded-l-lg shadow overflow-hidden">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Document</h2>
                    <DocumentList />
                </div>

                {iframeSrc === "" ? (
                    <div className="h-full w-full bg-gray-100 flex items-center justify-center">
                        <span>Select a reference to show the document.</span>
                    </div>
                ) : (
                    <iframe
                        key={keyUpdate}
                        className="h-full"
                        src={iframeSrc}
                        width="100%"
                        height="100%"
                    ></iframe>
                )}
            </div>

            {/* Chat Interface */}
            <div className="w-1/2 flex flex-col bg-white rounded-r-lg shadow overflow-hidden">
                <ScrollArea className="flex-grow p-4">
                    {messages.map(message => (
                        <div key={message.id} className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
                            <div className={`inline-block p-2 rounded-lg whitespace-pre-wrap text-sm ${message.role === 'user' ? 'bg-black text-white' : 'bg-gray-100 text-gray-800'}`}>
                                <Markdown className="custom-markdown" remarkPlugins={[remarkGfm]}>
                                    {message.content}
                                </Markdown>
                            </div>
                        </div>
                    ))}
                    {isLoading && (
                        <div className={`mb-4 text-left`}>
                            <div className={`inline-block p-2 rounded-lg whitespace-pre-wrap text-sm bg-gray-100 text-gray-800`}>
                                <Markdown className="custom-markdown" remarkPlugins={[remarkGfm]}>
                                    Searching...
                                </Markdown>
                            </div>
                        </div>
                    )}
                </ScrollArea>
                <div className="p-4 bg-white border-t">
                    <div className="flex space-x-2 mb-2">
                        <Input
                            type="text"
                            placeholder="Type your message..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                            className="w-full max-w-full overflow-hidden break-words"
                            style={{ wordWrap: 'break-word', overflowWrap: 'break-word'}}
                        />
                        <Button onClick={handleSend}>
                            <Send className="h-4 w-4" />
                            <span className="sr-only">Send</span>
                        </Button>
                        <Button onClick={handleClear} variant="outline">
                            <RotateCcw className="h-4 w-4" />
                            <span className="sr-only">Clear</span>
                        </Button>
                        <Button onClick={() => setShowConfig(!showConfig)} variant="outline">
                            <Settings className="h-4 w-4" />
                            <span className="sr-only">Settings</span>
                        </Button>
                    </div>
                    {showConfig && (
                        <div className="bg-gray-100 p-4 rounded-lg space-y-4">
                            <div>
                                <Label htmlFor="word-limit">Word Limit: {aiConfig.wordLimit}</Label>
                                <Slider
                                    id="word-limit"
                                    min={50}
                                    max={500}
                                    step={10}
                                    value={[aiConfig.wordLimit]}
                                    onValueChange={(value) => setAIConfig({ ...aiConfig, wordLimit: value[0] })}
                                />
                            </div>
                            <div>
                                <Label htmlFor="output-format">Output Format</Label>
                                <Select
                                    value={aiConfig.outputFormat}
                                    onValueChange={(value) => setAIConfig({ ...aiConfig, outputFormat: value })}
                                >
                                    <SelectTrigger id="output-format">
                                        <SelectValue placeholder="Select format" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="paragraph">Paragraph</SelectItem>
                                        <SelectItem value="bullet">Bullet Points</SelectItem>
                                        <SelectItem value="table">Tabulation</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Switch
                                    id="include-references"
                                    checked={aiConfig.includeReferences}
                                    onCheckedChange={(checked) => setAIConfig({ ...aiConfig, includeReferences: checked })}
                                />
                                <Label htmlFor="include-references">Include References</Label>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
