import { useState, useContext } from "react";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import { Context } from "context/GlobalState";
import handler from "./OpCodeBuilderHandler";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import { ArrowDownIcon, EyeIcon, EyeOffIcon } from "lucide-react"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Switch } from "components/ui/switch"

export default function OpCodeExecution() {
    const { 
        opCodes, isOpcodeLoading, handleGetSavedOpcodes,
        selectedOpCode, setSelectedOpCode,
        executeInput, setExecuteInput,
        executionResults, setExecutionResults,
        isExecuting, setIsExecuting,
        opCodesDetails,
        selectedOpCodesDetails, setSelectedOpCodesDetails,
    } = useContext(OpCodeBuilderContext)
    const { addNewNotifcation } = useContext(Context);
    const [stepsShown, setStepsShown] = useState(false)


    const handleOpCodeChange = (opcode) => {
        const selectedOpCodeDetails = opCodesDetails.find(op => op.opcode_id === opcode);
        if (selectedOpCodeDetails) {
            console.log("Found OpCode:", selectedOpCodeDetails);
            setSelectedOpCodesDetails(selectedOpCodeDetails)
            setSelectedOpCode(selectedOpCodeDetails.opcode_id)
        } else {
            console.log("OpCode not found.");
        }
    }

    const handleExecute = async () => {
        try {
            if (!selectedOpCode || executeInput.trim() === "") {
                addNewNotifcation("Please select an OpCode ID and give an input.", "warning");
                return;
            }
            setIsExecuting(true);
            setExecutionResults([]);
            const body = {
                "opcode_id": selectedOpCode,
                "org_id": "demo",
                "sub_org_id": "demo",
                "context": {
                  "email_content": executeInput.trim()
                }
            }
            console.log("execute body:", body);

            const res = await opCodeService.ExecuteOpCode(body)
            console.log("Execution result", res)
            setExecutionResults(res.data.execution_log);
            addNewNotifcation("OpCode executed successfully.", "success");
        } catch (e) {
            console.error("Error executing OpCode:", e);
            addNewNotifcation("Error when executing OpCode. Please try again.", "danger");
            setExecutionResults([]);
        } finally {
            setIsExecuting(false);
        }
    };

    return (
        <div className="mt-4 space-y-6">
            <div className="grid grid-cols-4 gap-4">
                <div className="space-y-2 col-span-3">
                    <Label>OpCode Selection</Label>
                    <Select value={selectedOpCode} onValueChange={handleOpCodeChange}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select OpCode ID" />
                        </SelectTrigger>
                        <SelectContent>
                            {opCodes.map((opCode, index) => (
                                <SelectItem key={index} value={opCode}>{opCode}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="col-span-1 flex items-end">
                    <Button onClick={handleGetSavedOpcodes} className="w-full flex gap-2" type="button" disabled={isOpcodeLoading}>{isOpcodeLoading ? "Loading Opcodes..." : "Reload Opcodes"}</Button>
                </div>
                
            </div>
            <div className="mx-auto p-2">
                <div className="space-y-2">
                    {selectedOpCodesDetails && selectedOpCodesDetails.steps ? (
                        <>
                            <h1 className="text-xl font-bold">{selectedOpCodesDetails.opcode_id}</h1>
                            <p className="text-gray-600 mb-6 text-sm">{selectedOpCodesDetails.description}</p>
                            <div className="flex items-center space-x-2">
                                <Switch
                                    checked={stepsShown}
                                    onCheckedChange={() => setStepsShown(!stepsShown)}
                                />
                                {stepsShown ? (
                                    <EyeIcon className="h-4 w-4 text-gray-500" />
                                ) : (
                                    <EyeOffIcon className="h-4 w-4 text-gray-500" />
                                )}
                            </div>
                            
                            { stepsShown &&
                                <div className="">
                                    {selectedOpCodesDetails.steps.map((step, index) => (
                                        <div key={step.id} className="flex flex-col items-center">
                                            <Card className="w-full max-w-2xl text-sm">
                                                <CardHeader>
                                                    <CardTitle>Step {index + 1}: {step.params.unique_name}</CardTitle>
                                                </CardHeader>
                                                <CardContent>
                                                    <p><strong>Type:</strong> {step.type}</p>
                                                    <p><strong>Query:</strong> {step.params.query}</p>
                                                    <p><strong>Output:</strong> {step.output.result}</p>
                                                </CardContent>
                                            </Card>
                                            {index < selectedOpCodesDetails.steps.length - 1 && (
                                                <ArrowDownIcon className="my-2" size={24} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            }
                        </>
                    ) : (
                        <p className="text-gray-500">Select an OpCode to view steps.</p>
                    )}
                </div>
            </div>
            <div className="space-y-2">
                <Label>Input</Label>
                <Textarea value={executeInput} rows="6" onChange={(e) => setExecuteInput(e.target.value)} placeholder="Enter input for execution" />
            </div>
            <Button onClick={handleExecute} className="w-full" disabled={isExecuting}>{isExecuting ? "Executing OpCode..." : "Execute OpCode"}</Button>
            {executionResults.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-lg font-semibold mb-4">Execution Results</h3>
                    {executionResults.map((result, index) => (
                        <div key={index} className="bg-muted p-4 rounded mb-4 shadow border">
                            <h4 className="font-semibold mb-2">Step {index + 1}</h4>
                            <p><strong>Action:</strong> {result.Action}</p>
                            <p><strong>Output:</strong> {
                                Object.entries(result.Outputs).map(([key, value], index) => (
                                    <div key={index} className="flex flex-col border p-2 rounded-sm bg-white shadow">
                                        <strong className="text-gray-500">{key}:</strong>
                                        <p className="whitespace-pre-wrap text-sm">{value}</p>
                                    </div>
                                ))
                            }</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
