import { useContext, useState } from "react";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import InputGroup from "./InputGroup";
import { Plus } from "lucide-react";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import { Context } from "context/GlobalState";
import { Label } from "components/ui/label";
import InputValidation from "components/InputValidation";
import handler from "./OpCodeBuilderHandler";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";

function validateSteps(steps) {
    let errorMessages = [];

    steps.forEach((step, index) => {
        let stepErrors = [];

        if (!step.modelName) {
            stepErrors.push("no instruction");
        }
        if (Array.isArray(step.input) && step.input.length === 0) {
            stepErrors.push("no input");
        }
        if (!step.output) {
            stepErrors.push("no output");
        }
        if (step.output){
            const {isValid, message} = handler.validateOutputName(step.output);
            if (!isValid) {
                stepErrors.push("invalid output");
            }
        }


        if (stepErrors.length > 0) {
            errorMessages.push(`Step ${index + 1} has ${stepErrors.join(', ')}.`);
        }

        
    });

    return {
        isValid: errorMessages.length === 0,
        message: errorMessages.length > 0 ? errorMessages.join(' ') : "All steps are valid."
    };
}

export default function OpCodeForm() {
    const { addNewNotifcation } = useContext(Context)
    const { 
        savedModels,
        opCodes, setOpCodes,
        isModelLoading, handleGetSavedModels,
        opCodeName, setOpCodeName,
        opCodeDescription, setOpCodeDescription,
        inputGroups, setInputGroups,
        isCreating, setIsCreating,
    } = useContext(OpCodeBuilderContext)

    const handleAddGroup = () => {
        setInputGroups([...inputGroups, { modelName: "", input: [], output: "" }]);
    };

    const handleRemoveGroup = (index) => {
        const newInputGroups = inputGroups.filter((_, i) => i !== index);
        setInputGroups(newInputGroups);
    };

    const createOpCode = async () => {
        // Validate the opCodeName first
        let { isValid: isOpCodeValid, message: opCodeMessage } = handler.validateOpCodeName(opCodeName.trim());
        if (!isOpCodeValid) {
            addNewNotifcation(opCodeMessage, "warning");
            return;
        }

        // Validate the inputGroups (steps)
        let { isValid: areStepsValid, message: stepsMessage } = validateSteps(inputGroups);
        if (!areStepsValid) {
            addNewNotifcation(stepsMessage, "warning");
            return;
        }

        const opCodeData = {
            opcode_id: opCodeName,
            description: opCodeDescription,
            org_id: "demo",
            sub_org_id: "demo",
            first_step: {
                step_id: "step_1",
                org_id: "demo",
                sub_org_id: "demo",
                unique_name: inputGroups[0].modelName,
                output_value: inputGroups[0].output,
                next_step_id: inputGroups[1] ? "step_2" : "",
                accumulate_output: true,
            },
            other_steps: inputGroups.slice(1).map((group, index) => {
                // Check the length of the input array
                const formattedInput = group.input.length > 1 
                    ? group.input.map((input) => `${input}: {{ output['${input}'] }}`).join('\n\n') 
                    : `{{ output['${group.input[0]}'] }}`; // Original format for single input
        
                return {
                    step_id: `step_${index + 2}`,
                    org_id: "demo",
                    sub_org_id: "demo",
                    unique_name: group.modelName,
                    query: formattedInput,
                    output_value: group.output,
                    next_step_id: inputGroups[index + 2] ? `step_${index + 3}` : "",
                    accumulate_output: true,
                };
            }),
        };
        console.log("OpcodeData:", opCodeData)

        try {
            setIsCreating(true);
            console.log("Saving OpcodeData...")
            const response = await opCodeService.CreateOpCode(opCodeData);
            console.log(response);

            addNewNotifcation("OpcodeData saved successfully.", "success")
        } catch (error) {
            console.error("Error creating OpCode:", error);
            if (error.response.data.detail === "400: OpCode already exists."){
                addNewNotifcation("Opcode already exist. Please choose another unique name.", "warning");
            }
        } finally {
            setIsCreating(false);
        }
    };

    const handleSubmit = async () => {
        const result = await createOpCode();
        if (result) {
            setOpCodes([...opCodes, { name: opCodeName, ...result }]);
            setOpCodeName("");
            setOpCodeDescription("");
            setInputGroups([{ modelName: "", input: [], output: "" }]);
        }
    };

    return (
        <div className="mt-4 space-y-4">
            <div>
                <Label>OpCode ID</Label>
                <InputValidation
                    value={opCodeName}
                    onChange={(e) => setOpCodeName(e.target.value)}
                    placeholder="Enter a unique name for this OpCode"
                    validation={handler.validateOpCodeName}
                    />
            </div>
            <div>
                <Label>OpCode Description</Label>
                <Textarea value={opCodeDescription} onChange={(e) => setOpCodeDescription(e.target.value)} placeholder="Enter a description for this OpCode" />
            </div>
            <div>
                <Button onClick={handleGetSavedModels} className="flex gap-2" type="button" disabled={isModelLoading}>{isModelLoading ? "Loading Instructions..." : "Reload Instructions"}</Button>
            </div>
            {inputGroups.map((group, index) => (
                <div className="flex flex-col gap-4">
                    <InputGroup 
                        key={index} 
                        group={group} 
                        index={index} 
                        savedModels={savedModels} 
                        handleRemoveGroup={handleRemoveGroup}
                        handleAddGroup={handleAddGroup}
                        inputGroups={inputGroups}
                        setInputGroups={setInputGroups}
                    />
                    <hr/>
                </div>
            ))}
            <div className="flex">
                <Button onClick={handleAddGroup} className="w-full flex gap-2" type="button"><Plus className="h-4 w-4" />Add Step</Button>
            </div>
            <div className="flex">
                <Button onClick={handleSubmit} className="w-full" type="button" disabled={isCreating}>
                    { isCreating ? "Creating OpCode..." : "Create OpCode" }
                </Button>
            </div>
        </div>
    );
}
