import { Bot, Files, Settings, Code, Antenna, FlaskConical } from "lucide-react";

export default {
    name: 'Sub Organization',
    list: [
        {name: 'BeX Assistants Center', path: 'bex-assistants', icon: <Bot className="h-5 w-5" />},
        {name: 'Assistants', path: 'assistants', icon: <Bot className="h-5 w-5" />},
        {name: 'Knowledge Builder', path: 'collection', icon: <Files className="h-5 w-5" />},
        {name: 'Channels Builder', path: 'channels', icon: <Antenna className="h-5 w-5" />},
        // {name: 'Op Codes', path: 'op-codes', icon: <Code className="h-5 w-5" />},
        {name: 'BeX AI Lab', path: 'ai-lab', icon: <FlaskConical className="h-5 w-5" />},
        {name: 'Settings', path: 'settings', icon: <Settings className="h-5 w-5" />},
    ]
}