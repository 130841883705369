/* eslint-disable max-lines */
import React, { useState, useEffect, useContext } from 'react'
import FileTreeItem from './FileTreeItem';
import FileListTable from './FileListTable';
import FileNavBar from './FileNavBar';
import FileActionBar from './FileActionBar';
import { useParams } from 'react-router-dom';
import { ragService } from 'api/services/PROJECT-O';
import { Context } from 'context/GlobalState';
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { LoaderModal } from 'components/LoaderModal';
import AlertModal from 'components/Alertmodal';
import { fileManagerService } from 'api/services/BEX/fileManager.service';
import { Trash2 } from 'lucide-react'

const removeFirstPrefix = (path) => {
    const parts = path.split('/');
    parts.shift(); // Remove the first element
    return parts.join('/'); // Join the remaining parts back together
};

function buildFileSystem(paths) {
    const fileSystem = [];
    let idCounter = 1;

    // Helper function to find or create a folder
    const findOrCreateFolder = (parentChildren, folderName, bucketName) => {
        const folder = parentChildren.find(child => child.name === folderName && child.type === "folder");
        if (folder) return folder;

        const newFolder = { id: String(idCounter++), name: folderName || '-', bucket_name: bucketName, type: "folder", children: [] };
        parentChildren.push(newFolder);
        return newFolder;
    };
    // Process each path
    paths.forEach(({ path, bucket_name, presigned_url, status, reason }) => {
        const parts = path.split('/');
        let currentChildren = fileSystem;

        parts.forEach((part, index) => {
            if (index === parts.length - 1) {
                // It's a file, add with bucket_name
                if (part){
                    currentChildren.push({ id: String(idCounter++), name: part, bucket_name, type: "file", presigned_url: presigned_url, prefix: path, status: status, reason: reason });
                }
            } else {
                // It's a folder, find or create it
                const folder = findOrCreateFolder(currentChildren, part, bucket_name);
                currentChildren = folder.children;
            }
        });
    });
    return fileSystem;
}

export default function FileManager({ project }) {
    const [fileSystem, setFileSystem] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState("1");
    const [currentPath, setCurrentPath] = useState([]);
    const [expandedFolders, setExpandedFolders] = useState([])
    const [delBtn, setDelBtn] = useState(false)
    const [delModal, setDelModal] = useState(false)
    const [deleteFiles, setDeleteFiles] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const { oragID, subOragID, collectionId } = useParams();
    const { addNewNotifcation } = useContext(Context)

    const findFolder = (id, items) => {
        for (let item of items) {
            if (item.id === id) return item;
            if (item.children) {
                const found = findFolder(id, item.children);
                if (found) return found;
            }
        }
        return null;
    };

    const handleSelect = (item) => { // Folder select
        setSelectedFolder(item.id);
        const newPath = getPath(item.id, fileSystem);
        setCurrentPath(newPath);

        const parentFolders = newPath.map(pathItem => pathItem.id)
        setExpandedFolders(prevExpanded => {
            const newExpanded = new Set([...prevExpanded, ...parentFolders])
            return Array.from(newExpanded)
        })
    };

    const handleToggleExpand = (id) => { // Execute when clicking folder expand icon
        setExpandedFolders(prev => 
            prev.includes(id) ? prev.filter(folderId => folderId !== id) : [...prev, id]
        )
    }

    // Get the path of a specific item from the file system
    const getPath = (id, items, path = []) => {
        for (let item of items) {
          if (item.id === id) return [...path, { id: item.id, name: item.name, bucket_name: item.bucket_name }]
          if (item.children) {
            const newPath = getPath(id, item.children, [...path, { id: item.id, name: item.name, bucket_name: item.bucket_name }])
            if (newPath.length) return newPath
          }
        }
        return []
    }

    const selectedFolderContent = findFolder(selectedFolder, fileSystem)?.children || [];

    const getFileSystem = async () => {
        try {
            let paths = [];
            let continuation_token = null;
            const collection = project.collections.find(item => item._id === collectionId);
            const bucket_name = `coll-${subOragID}-${collectionId}`;
            let hasMoreFiles = true;
    
            // Loop to keep fetching files until no continuation token is returned
            while (hasMoreFiles) {
                // Fetch files from the COS with the current continuation token
                const response = await ragService.listFilesCOS(
                    oragID,
                    bucket_name,
                    undefined,
                    undefined,
                    undefined,
                    continuation_token
                );

                // Process response and append documents to paths
                response.data.documents.forEach((doc) => {
                    if (!doc.key.endsWith("/")) {
                        paths.push({
                            path: `${collection.name}/${doc.key}`,
                            bucket_name,
                            presigned_url: doc.presigned_url,
                            status: doc?.metadata?.status || '-',
                            reason: doc?.metadata?.reason || ''
                        });
                    }
                });
    
                // If the collection has no documents, add an empty folder
                if (response.data.documents.length === 0) {
                    paths.push({ path: `${collection.name}/`, bucket_name });
                }
    
                // Update continuation token (if present) to fetch more files
                continuation_token = response.data.next_continuation_token || null;
    
                // If no continuation token is returned, it means all files are loaded
                hasMoreFiles = continuation_token !== null;
            }
    
            // Build the file system using the paths and bucket names
            const files = buildFileSystem(paths);
            setFileSystem(files);
        } catch (e) {
            console.error("Error", e);
            addNewNotifcation("Something went wrong while retrieving the documents.", "danger")
        }
    };

    const handleDeleteModal = (items) => {
        const filesToDelete = [];

        const getAllFiles = (folder) => {
            if (folder.type === 'file') {
                filesToDelete.push(removeFirstPrefix(folder.prefix));
            } else if (folder.type === 'folder' && folder.children) {
                folder.children.forEach(child => getAllFiles(child));
            }
        };

        items.forEach(item => {
            if (item.type === 'file') {
                filesToDelete.push(removeFirstPrefix(item.prefix));
            } else {
                getAllFiles(item);
            }
        });

        if (filesToDelete.length > 0) {
            setDeleteFiles(filesToDelete);
            setDelModal(true);
        } else {
            addNewNotifcation('No files found to delete', 'warning');
        }
    }

    const handleDelete = () => {
        setDelBtn(true)
        const fileOBj = {
            organizationId: oragID,
            bucketId: `coll-${subOragID}-${collectionId}`,
            files: deleteFiles,
        }
        console.log('fileOBj: ', fileOBj);
        
        fileManagerService.deleteFiles(fileOBj)
        .then((res) => {
            addNewNotifcation('File deleted successfully', 'success')
            getFileSystem()
            setDelModal(false)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wring', 'danger')
        }).finally(() => {
            setDelBtn(false)
            setSelectedItems([])
        })
    }

    const rowActions = [
        {'name': 'Delete', 'icon': <Trash2 className='w-4 h-4' />, 'onclick': handleDeleteModal},
    ]

    useEffect(() => {
        if (fileSystem.length > 0) {
            const defaultPath = getPath(fileSystem[0].id, fileSystem);
            setSelectedFolder(fileSystem[0].id);
            setCurrentPath(defaultPath);
        }
    }, [fileSystem]);

    useEffect(() => {
        setSelectedItems([])
    }, [currentPath]);

    return (
        <Card className='relative min-h-[500px] overflow-hidden'>
            {uploadLoading && <LoaderModal />}
            <CardHeader>
                <CardTitle> File Manager </CardTitle>
            </CardHeader>
            <CardContent>
                <FileNavBar getFileSystem={getFileSystem} currentPath={currentPath} setCurrentPath={setCurrentPath} setSelectedFolder={setSelectedFolder} setExpandedFolders={setExpandedFolders}/>
                <FileActionBar setUploadLoading={setUploadLoading} getFileSystem={getFileSystem} setFileSystem={setFileSystem} findFolder={findFolder} selectedFolder={selectedFolder} currentPath={currentPath} handleDeleteModal={handleDeleteModal} selectedItems={selectedItems}
                />
                <div className="flex flex-1 h-[300px] my-4">
                    <div className="w-1/4 overflow-y-scroll border-r p-2">
                        {fileSystem.map((item) => (
                            <FileTreeItem key={item.id} item={item} onSelect={handleSelect} selectedFolder={selectedFolder} expandedFolders={expandedFolders} onToggleExpand={handleToggleExpand} />
                        ))}
                    </div>
                    <div className="w-3/4 px-4 overflow-y-scroll">
                        <FileListTable getFileSystem={getFileSystem} findFolder={findFolder} selectedFolderContent={selectedFolderContent} handleSelect={handleSelect} selectedItems={selectedItems} setSelectedItems={setSelectedItems} rowActions={rowActions}/>
                    </div>

                </div>
            </CardContent>
            {/* delete alert modal */}
            <AlertModal loading={delBtn} openModal={delModal} setopenModal={setDelModal} onDelete={handleDelete} />
        </Card>
    );
}
