import axios from 'axios';

/**
 * Simple HTTP client for the Project O API.
 */
const projectOHttpClientStg = axios.create({
    baseURL: 'https://stgapp.coolriots.ai/projecto/api/v1',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

/**
 * Service class for performing OpCode operations.
 */
class OpCodeService {

    /**
     * Creates a new OpCode.
     * @param {Object} data - The data to create a new OpCode.
     * @returns {Promise<Object>} The response from the server regarding OpCode creation.
     */
    async CreateOpCode(data) {
        const response = await projectOHttpClientStg.post(`/opcodes/create_opcode/`, data);
        return response;
    }

    /**
     * Executes an OpCode.
     * @param {Object} data - The input data for executing the OpCode.
     * @returns {Promise<Object>} The response containing execution results.
     */
    async ExecuteOpCode(data) {
        const response = await projectOHttpClientStg.post(`/opcodes/execute_opcode/`, data);
        return response;
    }

    /**
     * Fetches OpCodes by organization and sub-organization.
     * @param {String} organizationId - The organization ID.
     * @param {String} subOrganizationId - The sub-organization ID.
     * @returns {Promise<Object>} The response containing the list of OpCodes.
     */
    async GetOpCodesByOrgSubOrg(organizationId, subOrganizationId) {
        const url = `/opcodes/get_opcodes/${organizationId}/${subOrganizationId}`;
        const response = await projectOHttpClientStg.get(url);
        return response;
    }
}

const opCodeService = new OpCodeService();
export { opCodeService };
