import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import DemoPrompt from "./DemoPrompt/index.js";
import LivePrompt from "./LivePrompt/index.js";
import { DemoProvider } from "../context/DemoPromptContext.js";
import { LiveProvider } from "../context/LivePromptContext.js";
import CodeSandbox from './CodeSandbox/CodeSandbox';
import ChatDocument from './ChatDocument/ChatDocument';
import { DocChatProvider } from '../context/DocChatContext';
import { SandboxProvider } from '../context/SandboxContext';
import '../prompting-and-rag-training.css';
import OpCodeBuilder from "./OpcodeBuilder/OpCodeCreator.jsx";
import { OpCodeBuilderProvider } from "../context/OpCodeBuilderContext.js";


export default function Laboratory() {
    return (
        <Tabs defaultValue="demo" className="w-full">
            
            <div className="p-2 px-2 py-2 h-fit bg-white items-center shadow sticky top-0 z-10 w-[calc(100%+4rem)]
            transform -translate-x-8 -translate-y-8 flex justify-center">
                <TabsList className="select-none">
                    <TabsTrigger className="text-xs" value="demo">Demo Prompt</TabsTrigger>
                    <TabsTrigger className="text-xs" value="live">Live Prompt</TabsTrigger>
                    <TabsTrigger className="text-xs" value="code">Code Sandbox</TabsTrigger>
                    <TabsTrigger className="text-xs" value="teacher">AI Teacher</TabsTrigger>
                    <TabsTrigger className="text-xs" value="opcode_builder">Opcode Builder</TabsTrigger>
                </TabsList>
            </div>
            
            <DemoProvider>
                <TabsContent className="w-full h-auto" value="demo">
                    <DemoPrompt />
                </TabsContent>
            </DemoProvider>

            <LiveProvider>
                <TabsContent className="w-full h-auto" value="live">
                    <LivePrompt />
                </TabsContent>
            </LiveProvider>

            <SandboxProvider>
                <TabsContent value="code">
                    <CodeSandbox/>
                </TabsContent>
            </SandboxProvider>
            
            <DocChatProvider>
                <TabsContent value="teacher">
                    <ChatDocument/>
                </TabsContent>
            </DocChatProvider>

            <OpCodeBuilderProvider>
                <TabsContent className="flex justify-center" value="opcode_builder">
                    <OpCodeBuilder/>
                </TabsContent>
            </OpCodeBuilderProvider>
            

        </Tabs>
    );
}



