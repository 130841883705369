import { Folder, File, Ellipsis} from 'lucide-react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "components/ui/table"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Badge } from '@mui/material';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';


const FileListTable = ({ selectedFolderContent, findFolder, handleSelect, getFileSystem, rowActions=[], selectedItems, setSelectedItems}) => {


    const toggleSelectAll = (checked) => {
        if (checked) {
          setSelectedItems(selectedFolderContent)
        } else {
          setSelectedItems([])
        }
      }
    
    const toggleSelectItem = (item, checked) => {
        if (checked) {
            setSelectedItems(prev => [...prev, item])
        } else {
            setSelectedItems(prev => prev.filter(selectedItem => selectedItem.id !== item.id))
        }
    }

    const actionCondition = (item) => {
        // Add logic to conditionally show row actions (if needed)
        return true; // Currently always showing the actions
    };

    return (
        <div>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-12">
                            <Checkbox
                                checked={selectedItems.length === selectedFolderContent.length && selectedFolderContent.length > 0}
                                onCheckedChange={toggleSelectAll}
                                aria-label="Select all"
                            />
                        </TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Action</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="overflow-y-scroll">
                    {selectedFolderContent.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>
                                <Checkbox
                                    checked={selectedItems.some(selectedItem => selectedItem.id === item.id)}
                                    onCheckedChange={(checked) => toggleSelectItem(item, checked)}
                                    aria-label={`Select ${item.name}`}
                                />
                            </TableCell>
                            <TableCell>
                                <div className="flex items-center max-w-[200px]">
                                    {item.type === "folder" ? (
                                        <div
                                            className="flex items-center cursor-pointer truncate"
                                            onClick={() =>
                                                handleSelect(item)
                                            }
                                        >
                                            <Folder className="text-yellow-600 min-w-4 h-4 mr-2" />
                                            {item.name}
                                        </div>
                                    ) : (
                                        <>
                                            <File className="min-w-4 h-4 mr-2" />
                                            <a target='_blank' href={item.presigned_url} className='truncate'>
                                                {item.name}
                                            </a>
                                        </>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>
                                {item.type === 'file' ? (
                                    <>
                                        {item.status === 'success' ? (
                                            <Badge variant="outline" className="px-1 rounded bg-green-500 text-green-50">
                                                {item.status}
                                            </Badge>
                                        ) :
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <div>
                                                            <Badge variant="outline" className="px-1 rounded bg-yellow-500 text-yellow-50">
                                                                {item.status}
                                                            </Badge>
                                                            <div className="text-xs max-w-[200px] truncate text-muted-foreground">
                                                                {item.reason}
                                                            </div>
                                                        </div>
                                                    </TooltipTrigger>
                                                    <TooltipContent className="bg-gray-900 text-white">
                                                        <p>{item.reason}</p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        }
                                    </>
                                ) : '-'}
                            </TableCell>
                            {actionCondition(item) && (
                                <TableCell>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button aria-haspopup="true" size="icon" variant="ghost" className="border-none bg-transparent">
                                                <Ellipsis className="h-4 w-4" />
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent align="end">
                                            {rowActions.map((action) => (
                                                <DropdownMenuItem
                                                    key={action.name}
                                                    onClick={() => action.onclick([item])}
                                                >
                                                    {action.icon}
                                                    <span className="ml-2">{action.name}</span>
                                                </DropdownMenuItem>
                                            ))}
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    );
};

export default FileListTable;