import { useEffect } from "react";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import { Plus, Trash2, ChevronDown } from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import InputValidation from "components/InputValidation";
import handler from "./OpCodeBuilderHandler";

export default function InputGroup({ group, index, savedModels, handleRemoveGroup, inputGroups, setInputGroups }) {

    const getPreviousOutputs = (currentIndex) => {
        if (currentIndex <= 0) return [];

        return inputGroups
            .slice(0, currentIndex)
            .map((group, index) => ({
                value: group.output || "",
                label: `Step ${index + 1} Output`,
            }))
            .filter((item) => item.value.trim() !== "");
    };

    const handleInputChange = (index, field, value) => {
        const newInputGroups = [...inputGroups];
        newInputGroups[index][field] = value;
        setInputGroups(newInputGroups);
    };
    useEffect(() => {
        handleInputChange(
            0,
            "input",
            ["email_content"],
        )
    }, [])

    return (
        <div className="space-y-2">
            <div className="flex flex-row gap-2 items-center">
                <h3 className="text-lg font-semibold">Step {index + 1}</h3>
                <div className="flex items-center">
                    {inputGroups.length > 1 && (
                        <Button variant="destructive" className="w-full" onClick={() => handleRemoveGroup(index)}>
                            <Trash2 className="h-5 w-5" />
                        </Button>
                    )}
                </div>
            </div>
            
            <div className="grid grid-cols-4 gap-4">
                <div>
                    <Label htmlFor={`modelName-${index}`}>Instruction Name</Label>
                    <Select value={group.modelName} onValueChange={(value) => {handleInputChange( index, "modelName", value, )}}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select Instruction" />
                        </SelectTrigger>
                        <SelectContent>
                            {savedModels.map((name) => (
                                <SelectItem key={name} value={name}>{name}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor={`input-${index}`}>Input</Label>
                    {index === 0 ? (
                        <Input
                            id={`input-${index}`}
                            value={group.input.join(", ",)}
                            onChange={(e) =>
                                handleInputChange(
                                    index,
                                    "input",
                                    ["email_content"],
                                )
                            }
                            className=""
                            disabled={true}
                        />
                    ) : (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="outline" role="combobox" className="w-full justify-between">
                                    {group.input.length > 0 ? `${group.input.length} selected` : "Select inputs"}
                                    <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-[200px]">
                                <DropdownMenuCheckboxItem
                                    key={Date.now()}
                                    checked={group.input.includes(
                                        "email_content",
                                    )}
                                    onCheckedChange={(checked) => {
                                        const newInputs = checked ? [
                                                        ...group.input,
                                                        "email_content",
                                                    ] : group.input.filter((input) => input !== "email_content");
                                        handleInputChange(
                                            index,
                                            "input",
                                            newInputs,
                                        );
                                    }}
                                >
                                    {"Step 1 Input"}
                                </DropdownMenuCheckboxItem>
                                {getPreviousOutputs(index,).map((item) => (
                                    <DropdownMenuCheckboxItem
                                        key={item.label}
                                        checked={group.input.includes(
                                            item.value,
                                        )}
                                        onCheckedChange={(checked) => {
                                            const newInputs = checked ? [
                                                            ...group.input,
                                                            item.value,
                                                        ] : group.input.filter((input) => input !== item.value);
                                            handleInputChange(
                                                index,
                                                "input",
                                                newInputs,
                                            );
                                        }}
                                    >
                                        {item.label}
                                    </DropdownMenuCheckboxItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>
                <div className="col-span-2">
                    <Label htmlFor={`output-${index}`}>
                        Output
                    </Label>
                    <InputValidation
                        id={`output-${index}`}
                        value={group.output}
                        onChange={(e) =>
                            handleInputChange(
                                index,
                                "output",
                                e.target.value,
                            )
                        }
                        validation={handler.validateOutputName}
                    />
                </div>
                
            </div>
            
        </div>
    );
}
